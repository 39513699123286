import api from '../../assets/api.js';

class Documents {
  constructor() {
    this.namespaced = true;
    
    this.state = {
      documents: [],
      documentTypes: [],
      unSignedDocumentTypes: [],
      settingsDataWithLevel: {},
      deliveryMethods: [],
      messageDeliveryMethods: [],
      deliveryMethod: '',
      messageDeliveryMethod: '',
    }

    this.getters = {
      getDocuments: state => state.documents,
      getDocumentTypes: state => state.documentTypes,
      getUnSignedDocumentTypes: state => state.unSignedDocumentTypes,
      getSettingsData: state => state.settingsDataWithLevel,
      getDeliveryMethods: state => state.deliveryMethods,
      getMessageDeliveryMethods: state => state.messageDeliveryMethods,
      getDeliveryMethod: state => state.deliveryMethod,
      getMessageDeliveryMethod: state => state.messageDeliveryMethod
    }

    this.actions = {
      async fetchDocuments({getters, commit}, data){
        const { component, params} = data;
        const r = await api.get(component, params);
        const documents = r.documents ? r.documents : r;
        const tempDocs = [...getters.getDocuments, ...documents];
        commit('setDocuments', tempDocs);
        return documents;
      },
      async fetchDocumentTypes({commit, getters}) {
        if(getters.getDocumentTypes.length) return;
        const r = await api.get(this, api.DOCUMENT_TYPES.slice(0, -1));
        if(r && r.types && r.types.length) {
          commit('setDocumentTypes', r.types);
        }
      },
      async fetchUnSignedDocumentTypes({commit, getters}) {
        if(getters.getUnSignedDocumentTypes.length) return;
        const resp = await api.get(this, api.DOCUMENT_TYPES.slice(0, -1) + '?document_type=un-signed');
        if(resp && resp.types && resp.types.length) {
          commit('setUnSignedDocuments', resp.types);
        }
      },
      async downloadDocument({}, params){
        const r = await api.get(this, api.DOCUMENT + params);
        return r;
      },
      async fetchSettingsData({getters, commit}, data){
        const { component, params, level } = data;
        const resp = await api.get(component, params);
        const documents = resp.documents ? resp.documents : resp;
        const settingsData = getters.getSettingsData[level]
        let tempData = documents;
        if(settingsData) {
          tempData = [...settingsData, ...documents];
        }
        commit('setSettingsData', { this: component, level: level, data: tempData });
        return documents;
      },
      async fetchDeliveryMethods({getters, commit}, data){
        //if(getters.getDeliveryMethods.length) return;
        let property_id = data;
        let response = [];
        if (property_id) {
          response = await api.get(
            this.$app,
            api.PROPERTIES +
              data +
              "/mailhouses/delivery-methods?types[]=email&types[]=mail"
          );
        } else {
          response = await api.get(
            this.$app,
            api.MAILHOUSES + "/delivery-methods?types[]=email&types[]=mail"
          );
        }
        if(response && response.deliveryMethods && response.deliveryMethods.length) {
          commit('setDeliveryMethods', response.deliveryMethods);
        }
      },
      async fetchMessageDeliveryMethods({getters, commit}, data){
        //if(getters.getDeliveryMethods.length) return;
        const r = await api.get(this.$app, api.PROPERTIES + data + '/mailhouses/delivery-methods?types[]=email&types[]=sms');
        if(r && r.deliveryMethods && r.deliveryMethods.length) {
          let deliveryMethods = r.deliveryMethods.filter( m => {
            return m.gds_key == 'standard_email' || m.gds_key == 'standard_sms';
          })
          commit('setMessageDeliveryMethods', deliveryMethods);
        }
      },
      getDeliveryMethodById({getters, commit}, id){
        if(getters.getDeliveryMethods.length){
          commit('setDeliveryMethod', getters.getDeliveryMethods.filter( method =>  method.id === id )[0] )
        }
      },
      getMessageDeliveryMethodById({getters, commit}, id){
        if(getters.getMessageDeliveryMethods.length){
          commit('setMessageDeliveryMethod', getters.getMessageDeliveryMethods.filter( method =>  method.id === id )[0] )
        }
      }
    }

    this.mutations = {
      setDocuments: (state, payload) => {
        state.documents = payload;
      },
      setDocumentTypes: (state, payload) => {
        state.documentTypes = payload;
      },
      setUnSignedDocuments: (state, payload) => {
        state.unSignedDocumentTypes = payload;
      },
      setSettingsData: (state, payload) => {
        payload.this.$set(state.settingsDataWithLevel,payload.level,payload.data);
      },
      setDeliveryMethods: (state, payload) => {
        state.deliveryMethods = payload;
      },
      setMessageDeliveryMethods: (state, payload) => {
        state.messageDeliveryMethods = payload;
      },
      setDeliveryMethod: (state, payload) => {
        state.deliveryMethod = payload;
      },
      setMessageDeliveryMethod: (state, payload) => {
        state.messageDeliveryMethod = payload;
      }
    }
  }
}

export default Documents;