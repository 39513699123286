import api from '../../assets/api.js';
class Automation {
  constructor() {
    this.namespaced = true;

    this.state = {
      allWorkflows: [],
      allAutomationTypes: [],
      currentTimeLine: [],
      missingActions: [
        // {
        //   type: "Missing Fees",
        //   details: [
        //     {
        //       property_number: "101",
        //       property_name: "Tustin",
        //       actions: [
        //         {
        //           space_type_timeline: "Storage Timeline",
        //           names: ["Fee 1", "Fee 2"],
        //         },
        //         {
        //           space_type_timeline: "Parking Timeline",
        //           names: ["Fee 3", "Fee 4"],
        //         },
        //       ],
        //     },
        //     {
        //       property_number: "102",
        //       property_name: "Seal Beach",
        //       actions: [
        //         {
        //           space_type_timeline: "Storage Timeline",
        //           names: ["Fee 5", "Fee 6"],
        //         },
        //         {
        //           space_type_timeline: "Parking Timeline",
        //           names: ["Fee 7", "Fee 8"],
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   type: "Missing Delivery Methods",
        //   details: [
        //     {
        //       property_number: "201",
        //       property_name: "Los Angeles",
        //       actions: [
        //         {
        //           space_type_timeline: "Storage Timeline",
        //           names: ["DM 1", "DM 2"],
        //         },
        //         {
        //           space_type_timeline: "Parking Timeline",
        //           names: ["DM 3", "DM 4"],
        //         },
        //       ],
        //     },
        //     {
        //       property_number: "202",
        //       property_name: "Irvine",
        //       actions: [
        //         {
        //           space_type_timeline: "Storage Timeline",
        //           names: ["DM 5", "DM 6"],
        //         },
        //         {
        //           space_type_timeline: "Parking Timeline",
        //           names: ["DM 7", "DM 8"],
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   type: "Missing Documents with Delivery Method for Messages",
        //   details: [
        //     {
        //       property_number: "301",
        //       property_name: "Newport Beach",
        //       actions: [
        //         {
        //           space_type_timeline: "Storage Timeline",
        //           documents: [
        //             {
        //               document_name: "Document 1",
        //               dm_names: ["DM 1", "DM 2"],
        //             },
        //             {
        //               document_name: "Document 2",
        //               dm_names: ["DM 3", "DM 4"],
        //             },
        //           ],
        //         },
        //         {
        //           space_type_timeline: "Parking Timeline",
        //           documents: [
        //             {
        //               document_name: "Document 3",
        //               dm_names: ["DM 5", "DM 6"],
        //             },
        //             {
        //               document_name: "Document 4",
        //               dm_names: ["DM 7", "DM 8"],
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //     {
        //       property_number: "302",
        //       property_name: "Santa Ana",
        //       actions: [
        //         {
        //           space_type_timeline: "Storage Timeline",
        //           documents: [
        //             {
        //               document_name: "Document 5",
        //               dm_names: ["DM 9", "DM 10"],
        //             },
        //             {
        //               document_name: "Document 6",
        //               dm_names: ["DM 11", "DM 12"],
        //             },
        //           ],
        //         },
        //         {
        //           space_type_timeline: "Parking Timeline",
        //           documents: [
        //             {
        //               document_name: "Document 7",
        //               dm_names: ["DM 13", "DM 14"],
        //             },
        //             {
        //               document_name: "Document 8",
        //               dm_names: ["DM 15", "DM 16"],
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
      notification: {
        type: '',
        msg: ''
      },
      triggerActionDependencyTypes: [],
      timeFrames: [
        { id: 1, slug: 'immediately', content: 'Immediately' },
        { id: 2, slug: 'days', content: 'Day(s)' },
      ],
      allEvents:[]
    };

    this.getters = {
      getAllWorkflows: (state) => state.allWorkflows,
      getAllAutomationTypes: (state) => state.allAutomationTypes,
      getCurrentTimeLine: (state) => state.currentTimeLine,
      getMissingActions: (state) => state.missingActions,
      getNotification: (state) => state.notification,
      getTriggerActionDependencyTypes: (state) => state.triggerActionDependencyTypes,
      getTimeFrames: (state) => state.timeFrames,
      getAllEvents: (state) =>  state.allEvents 
    };

    this.mutations = {
      setCurrentTimeLine(state, payload) {
        state.currentTimeLine = payload;
      },
      setMissingActions(state, payload){
        state.missingActions = payload;
      },
      setAllAutomationTypes (state,payload){
        state.allAutomationTypes=payload;
      },
      setNotification(state, payload) {
        state.notification = payload;
      },
      setAllWorkflows(state, payload) {
        state.allWorkflows = payload;
      },
      setTriggerActionDependencyTypes(state, payload) {
        state.triggerActionDependencyTypes = payload;
      },
      setAllEvents(state, payload) {
        state.allEvents = payload;
      }
    };

    this.actions = {
      async setStates({getters,commit}, data) {
        try {
          const {state}=data;           
          commit('setCurrentTimeLine', getters.getCurrentTimeLine);
        } catch (error) {
          console.error('Error fetching currentTimeLine:', error);
          commit('setNotification', {type: 'error', msg: error});
        }
      },
      async setMissingActions({getters,commit}, data) {
        try {
          const {state}=data;           
          commit('setCurrentTimeLine', getters.getCurrentTimeLine);
        } catch (error) {
          console.error('Error fetching currentTimeLine:', error);
          commit('setNotification', {type: 'error', msg: error});
        }
      },
      async fetchAllAutomations({ getters, commit }, data) {
        try {
          let response = await api.get(this.$app, api.AUTOMATION);
          commit('setAllAutomationTypes', response.automations);
        } catch (error) {
          commit('setNotification', {type: 'error', msg: error});
        }
      },
      // func to get the details of all workflows
      async fetchAllWorkflows({ getters, commit }, data) {
        let { automation_type_id, property_id = null } = data;
        try {
          let res = await api.get(this.$app, `${api.AUTOMATION}/${automation_type_id}`, { ...(property_id ? { property_id } : {}) });
          commit('setAllWorkflows', res.automation_hierarchy);
        } catch (error) {
          commit('setNotification', {type: 'error', msg: error});
        }
      },
      // func to set the automation notifications
      setNotification({ getters, commit }, notification){
        let {type = '', msg = ''} = notification;
        commit('setNotification', {type: type, msg: msg});
      },
      // func to set the trigger action dependencies types
      async fetchTriggerActionDependencyTypes({ getters, commit }, data){
        let { automation_category_id = null } = data;
        try {
          let res = await api.get(this.$app, `${api.TRIGGERS}dependency-types/${automation_category_id}`);
          commit('setTriggerActionDependencyTypes', res.dependency_types);
        } catch (error) {
          commit('setNotification', {type: 'error', msg: error});
        } 
      },
      // func to set the trigger action dependencies types
      async fetchAllEvents({ getters, commit }, data){ 
        let { trigger_group_id = null, trigger_id =null } = data;
        try {
          let res = await api.get(this.$app, `${api.TRIGGERS}groups/${trigger_group_id}/all-actions?trigger_id=${trigger_id}`);
          commit('setAllEvents', res.allEvents);
        } catch (error) {
          commit('setNotification', {type: 'error', msg: error});
        } 
      }

    };
  }
}
export default Automation;
